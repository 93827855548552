<template>
    <a
        href="/organizations/settings"
        class="link"
        @click.prevent="handleRedirect">
        More details
    </a>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'MoreDetails',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        ...mapActions([
            'updateAgencyById'
        ]),
        async handleRedirect() {
            await this.updateAgencyById(this.item.id);
            this.$router.push('/organizations/settings');
        }
    }
};
</script>

<style lang="scss" scoped>
.link {
    color: $blue-btn;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
}
</style>
